import styled from "styled-components";

export const PageWrapper = styled.section`
  background: #e5e5e5;
  padding-bottom: 200px;
  @media only screen and (max-width: 1400px) {
    padding-bottom: 57px;
  }
  @media only screen and (max-width: 800px) {
    padding-bottom: 63px;
  }
  .title {
    width: 292px;
    margin: 0 auto;
    padding: 86px 0 57px;
    @media only screen and (max-width: 1400px) {
      width: 160px;
    }
  }
  p {
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: rgba(47, 49, 55, 0.5);
    padding-bottom: 86px;
    @media only screen and (max-width: 1400px) {
      display: none;
    }
  }
  .containerConvenios {
    height: 800px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    @media only screen and (max-width: 1400px) {
      height: 450px;
    }
    @media only screen and (max-width: 800px) {
      height: auto;
    }
    .itemConvenios {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding-bottom: 16px;
      .point {
        width: 12px;
        height: 12px;
        background: #69cf67;
        border-radius: 50%;
      }
      p {
        text-transform: uppercase;
        text-align: left;
        padding-bottom: 0;
        padding-left: 16px;
        font-size: 32px;
        line-height: 161%;
        letter-spacing: 0.05em;
        color: #545454;
        @media only screen and (max-width: 1400px) {
          font-size: 16px;
          line-height: 161%;
          display: block;
        }
      }
    }
  }
  .container {
    @media only screen and (max-width: 1400px) {
      max-width: 700px;
      padding: 0 64px 0 128px;
    }
    @media only screen and (max-width: 800px) {
      max-width: 300px;
      padding: 0 24px 58px;
    }
  }
  .bk1 {
    @media only screen and (max-width: 1400px) {
    }
    h2 {
      @media only screen and (max-width: 800px) {
        padding-bottom: 15px;
      }
    }
    p {
      text-align: left;
      padding: 15px 0 0;
      max-width: 544px;
      @media only screen and (max-width: 1400px) {
        display: block;
        padding-right: 20px;
      }
      @media only screen and (max-width: 800px) {
        padding-bottom: 42px;
      }
    }
    a {
      @media only screen and (max-width: 1050px) {
        padding: 24px 50px;
      }
    }
  }
`;
