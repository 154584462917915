import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";

import Layout from "../components/Layout";
import * as S from "../styles/convenios/styles";

const Convenios = (data) => {
  return (
    <Layout>
      <S.PageWrapper>
        <div className="title t1">
          <h2>Convênios</h2>
          <div className="border"></div>
        </div>
        <p>Confira aqui seu convênio</p>
        <div className="containerConvenios container">
          {data.data.markdownRemark.frontmatter.convenios.map((item) => (
            <div className="itemConvenios">
              <div className="point"></div>
              <p>{item.text}</p>
            </div>
          ))}
        </div>
        <div className="block2 bk1">
          <div>
            <h2>Ficou com dúvidas?</h2>
            <p>
              Fale com um de nossos atendentes para sanar todas as suas dúvidas
            </p>
          </div>
          <div className="block2Btn">
            <Link to="/contact">Fale conosco</Link>
          </div>
        </div>
      </S.PageWrapper>
    </Layout>
  );
};

const ConveniosPage = ({ data }) => {
  const { markdownRemark: convenio } = data;
  return (
    <Layout>
      <Convenios text={convenio.frontmatter.text} />
    </Layout>
  );
};
ConveniosPage.propTypes = {
  data: PropTypes.object.isRequired,
};
export default Convenios;

export const pageQuery = graphql`
  query Convenios {
    markdownRemark(frontmatter: { templateKey: { eq: "convenios" } }) {
      frontmatter {
        convenios {
          text
        }
      }
    }
  }
`;
